@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap");

.radioCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 0px 15px;
}

.radioCard.active {
  border: 1px solid #4080ff;
}

.radioCard > .text {
  margin-left: 20px;
  font-size: 16px;
  color: #4a5568;
}
